import React from 'react'
import { HeaderFormWrap, ButtonWrap } from './search-form.style'
import Button from '../../../ui/button'


const HeaderForm = () => {
    

    return (
        <HeaderFormWrap>
                <ButtonWrap>
                    <Button 
                        to="#navbar-cta-form"
                        hover="2"
                    >
                        Start a Project
                    </Button>
                </ButtonWrap>
        </HeaderFormWrap>
    )
}



export default HeaderForm
